<template>
<div class="ambassador-background">
  <Header />
     <div v-if="showForm" class="index-2 mt-5 mx-auto col-12 banner overflow-hidden">
       
            <div class="row mt-3">
                 
                 <div class="col-4 d-flex justify-content-center">
                        <img class="side-image pt-5" src="@/assets/certified-facilitator.png" />
                 </div>

                 <div class="col-3 d-flex justify-content-center">
                     <img class="upper-image" src="@/assets/ambassador.png" />
                 </div>

                 <div class="col-4 d-flex justify-content-center">
                    <img class="side-image pt-5" src="@/assets/practitioner.png" />
                 </div>
         
            </div>


    </div>

          <div v-if="showForm" id="workshop-form" class=" mt-3 index-2 mx-auto col-sm-12 col-md-12">
            <div class="row mb-4">
                
                  <div class="col-1 d-flex justify-content-end px-0">
                        
                  </div>
                  <div class="col-10 px-0 d-flex justify-content-center">
                    <img src="@/assets/estrella-roja.svg" class="quote-red-star mb-1" />
                    <div class="values-title align-bottom trxt-center">   Activate your powers   </div>
                    <img src="@/assets/estrella-roja.svg" class="quote-red-star mb-1" />
                  </div>
                <div class="col-1 d-flex justify-content-start px-0">
                       
                  </div>
              </div>

            <div class="row">
               <!-- form -->
                <div class="col-12">
                 <b-form  >
               


                <div class="form-group">
                    <div class="row blue-form">
                <div for="name" class="col-4 form-label">*Name:</div>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <div for="email" class="col-4 form-label">*Email:</div>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                
                  <div class="form-group">
                    <div class="row blue-form">
                        <label for="mobile" label="*Mobile:" class="col-4">*Mobile:</label>
                            <div class="col-4 mr-0"> 
                                <select v-model="form.countryCode" class="menu-select py-2 pr-5">
                                    <option   v-for="(code, key)  in this.phoneCodes" :key="key" :value="code.dial_code">{{code.code+code.dial_code}}</option>
                                </select>
                            </div>
                            <div class="col-4 ml-0 pl-0">
                                <input v-model="form.mobile" type="phone" class="mx-0 form-control blue-form" name="mobile" id="mobile">
                            </div>
                    </div>
                </div>


                 <div class="form-group">
                    <div class="row blue-form">
                        <div  class="col-4 pr-0 form-label">*I want to become:</div>
                        <div class="col-8 pl-0"> 
                            <select v-model="form.interest" class="ltr-select px-5">
                                <option value="A Practitioner">A Practitioner</option>
                                <option value="A Facilitator">A Facilitator</option>
                                <option value="An Ambassador">An Ambassador</option>
                                <option value="All">All</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-lg-4">Comments:</label>
                        <div class="col-lg-8">
                            <textarea v-model="form.comments"  
                            rows="4" 
                            class="form-control blue-form comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2c ee4;  color: #000000;
                                    font-weight: bold; font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-8 form-check d-flex justify-content-center">
                     <div class="align-self-center">
                        <input type="checkbox" class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1">   <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a></label>
                     </div>
                </div>

                 <div class="col-4 form-check px-0 d-flex justify-content-end">
                    <div v-on:click="send()" class="contact-us-button  clickable" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></div>
                </div>

                </div>
                
                 </b-form>
                </div>
               <!--enf form -->
        </div>
        </div>


  <Footer class="mt-5"/>
</div>
</template>

<script>
// S E R V I C E S


import EmailService from "@/service/email"

import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import phoneCodes from "@/data/phoneCodes"

  export default {
     components: {
       Header,
       Footer
  },
    data() {
      return {
        jobs: [],
        isLoged:false,
        formSuccess:false,
         phoneCodes:[],
        form:{
            name:"",
            mobile:"",
            countryCode:"+52",
            email:"",
            comments:"",
            interest:"",
            subject:"Activate your powers"
        }
      }
    },

    created() {
    this.phoneCodes = phoneCodes.phoneCodes;
  },

    mounted(){
      window.scrollTo(0,0);
      this.checkLogin();
      
    },

   watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showForm = false;
          }, 3000);
        }
      }
    },
    methods:{

      checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },


      send(){
        console.log('envio nail')
          EmailService.sendEmail(this.form).then(res=>{
            window.scrollTo(0,0);
            console.log('exito'+ res)
            this.formSuccess = true;
          }).catch(()=>{
            console.log('error')
          })
      },

      logout(){
        sessionStorage.clear();
        this.isLoged = false;
      }
      
    }
  }
</script>

